import React from 'react';
import { Button, Flex, Text } from "@chakra-ui/react";
import { UnitAvailabilityPropTypes } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/unitAvailability";
import { localisable } from "@v10-codeparva/gatsby-theme-v10-tfw/src/generic/localisable";
import { AMENITIES_ICON_MAPPING,Icon } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/index";
// import { useNavigate } from "react-router-dom";
import { navigate } from "gatsby-link";
import { AMENITIES_SECTION_PROPS, BUTTON_SECTION_PROPS, COMMON_BUTTON_PROPS, RATE_SECTION_PROPS } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/unitAvailability/constants";

export const RenderUnitAvailabilityTable = ({ permissions, unitTypeData = [] }: UnitAvailabilityPropTypes.IUnitAvailabilityProps) => {

    const { isMoveInEnabled, isReservationEnabled, moveInButtonLabel, reserveButtonLabel, unavailableLabel,
        shallShowIcons, shallDisplayWebRateColumn, webRateColumnDescription, isWebRatePreferredForRate,
        shallDisplayDepositInRateSection } = permissions || {};
    let unitCategoryList = [] as UnitAvailabilityPropTypes.IUnitCategoryData[][];

    if (!!unitTypeData.length) {
        unitCategoryList = unitTypeData.map(({ IsClimateControlled, IsOutdoor, IsMobile, IsAutomobile }) => [
            { category: localisable.climateControl, value: IsClimateControlled, icon: 'Climate Controlled Units Available' },
            { category: localisable.outdoorParking, value: IsOutdoor, icon: 'Drive Up Units' },
            { category: localisable.mobile, value: IsMobile, icon: 'Moving Van Available' },
            { category: localisable.autoMobile, value: IsAutomobile, icon: 'Truck Rentals' },
        ].filter(({ value }) => value));
    }

    const RenderAmenitiesSection = ({ description, index }: UnitAvailabilityPropTypes.TAmenitiesProps) => {
        return <Flex direction='column' padding={{ sm: '8px' }} w='33.33%' justifyContent='center'>
            <Text textStyle='subtitle' textAlign='center'>{description}</Text>
            {
                !!unitCategoryList[index].length && unitCategoryList[index].map((amenitiesObject: any, index1) => {
                    return <Flex
                        key={index1}
                        direction='column'
                        {...AMENITIES_SECTION_PROPS}
                    >
                        {shallShowIcons && <Icon size={'default'} className={AMENITIES_ICON_MAPPING[amenitiesObject['icon']]} />}
                        <Text textStyle='body1' textAlign='center'>{amenitiesObject['category']}</Text>
                    </Flex>
                })
            }
        </Flex>
    }

    const RenderRateSection = ({ monthlyRate, depositAmount, shallDisplayWebRateColumn }: UnitAvailabilityPropTypes.TRateProps) => {
        return <Flex direction='column' justifyContent='center' w='33.33%'>
            <Flex direction='column' {...RATE_SECTION_PROPS}>
                <Text padding='8px' textStyle='body1'>{localisable.rate}</Text>
                <Text textStyle='subtitle' textDecoration={shallDisplayWebRateColumn ? 'line-through' : 'none'}>{`\$${monthlyRate.toFixed(2)}`}</Text>
            </Flex>
            {
                shallDisplayDepositInRateSection && !!depositAmount && <Flex direction='column' {...RATE_SECTION_PROPS}>
                    <Text padding='8px' textStyle='body1'>{localisable.deposit}</Text>
                    <Text textStyle='subtitle'>{`\$${depositAmount.toFixed(2)}`}</Text>
                </Flex>
            }
        </Flex>
    }

    const RenderWebRateSection = ({ webRate }: UnitAvailabilityPropTypes.TWebRateProps) => {
        return <Flex direction='column' justifyContent='center' w='33.33%'>
            {webRate && <Flex direction='column' {...RATE_SECTION_PROPS} textAlign='center'>
                <Flex direction='column' color='red' alignItems='center' dangerouslySetInnerHTML={{ __html: webRateColumnDescription || '' }} />
                <Text textStyle='subtitle'>{`\$${webRate?.toFixed(2)}`}</Text>
            </Flex>}
        </Flex >
    }

    const RenderButtonSection = ({ unitTypeId, isUnitsAvailable }: UnitAvailabilityPropTypes.TButtonProps) => {
        return <Flex direction='column' w='33.33%' {...BUTTON_SECTION_PROPS}>
            {
                isUnitsAvailable ?
                    <Flex direction={{ base: 'column', sm: 'row' }} wrap={{ sm: 'wrap' }} gap='8px' {...BUTTON_SECTION_PROPS}>
                        {
                            !!isReservationEnabled && <Button
                                onClick={() => navigate('reserve', {
                                    state: { selectedUnitType: unitTypeId }
                                })}
                                {...COMMON_BUTTON_PROPS}
                            >
                                {reserveButtonLabel}
                            </Button>
                        }
                        {
                            !!isMoveInEnabled && <Button
                                onClick={() => navigate('move-in', {
                                    state: { selectedUnitType: unitTypeId }
                                })}
                                {...COMMON_BUTTON_PROPS}
                            >
                                {moveInButtonLabel}
                            </Button>
                        }
                    </Flex> :
                    <Flex wrap='wrap' maxW='100%' textAlign='center' wordBreak='break-word'>
                        <Text textStyle='body1'>{unavailableLabel}</Text>
                    </Flex>
            }
        </Flex>
    }

    return <>
        {
            !!unitTypeData.length && <Flex direction='column' gap='8px'>
                {
                    unitTypeData.map(({ Description, UnitTypeID, MonthlyRate, WebRate, DepositAmount, IsUnitsAvailable }, index) => {
                        let monthlyRate = MonthlyRate;
                        if (isWebRatePreferredForRate) {
                            monthlyRate = WebRate || MonthlyRate;
                        }
                        return (
                            <Flex
                                key={index}
                                bg={index % 2 === 0 ? 'table.primary' : 'table.secondary'}
                                padding='4px 0px'
                                w='100%'
                            >
                                <RenderAmenitiesSection description={Description} index={index} />
                                <RenderRateSection monthlyRate={monthlyRate} depositAmount={DepositAmount} shallDisplayWebRateColumn={shallDisplayWebRateColumn} />
                                {shallDisplayWebRateColumn && <RenderWebRateSection webRate={WebRate} />}
                                {
                                    !Description?.toLowerCase().includes('barn') ?
                                        <RenderButtonSection isUnitsAvailable={IsUnitsAvailable} unitTypeId={UnitTypeID} /> :
                                        <Flex direction='column' w='33.33%' {...BUTTON_SECTION_PROPS}>
                                            <Flex wrap='wrap' maxW='100%' textAlign='center' wordBreak='break-word'>
                                                <Text textStyle='body1'>Please call our office for more details</Text>
                                            </Flex>
                                        </Flex>
                                }
                            </Flex>
                        )
                    })
                }
            </Flex>
        }
    </>

}